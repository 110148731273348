import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";

import { ContainerDefault } from "../container/container";
import { Action, ActionSecondary } from "../action/action";
import Player from "../player/player";
import { Signature, SignatureMobile } from "../graphical/signature";

import { font } from "../../theme";

// Audio
import CommercialAudio from "../../audio/commercial.mp3";
import ImagingAudio from "../../audio/imaging.mp3";

// Images
import CommercialImage from "../../images/commercial.jpeg";
import ImagingImage from "../../images/imaging.jpeg";

export function IntroComponentMobile() {
  return (
    <IntroContainer>
      <ContainerDefault>
        <IntroGridMobile className="py-10">
          <IntroCard className="block p-8 mb-8">
            <h2>About me</h2>
            <p>
              I’m Shirlie Randall, and have been the voice for numerous brands
              and projects for over 20 years! I can adapt my voice to whatever
              style your project requires, from bright and upbeat to smooth and
              sultry or natural and warm to cool and fresh, plus many more.
            </p>

            <p className="mb-20">
              With excellent communication skills, I am renowned with
              <br />
              producers as a female voice over who can “nail a script” <br />
              on the first take.
            </p>

            <SignatureMobile />

            <Actions className="px-8 pb-10">
              <Action to="contact" value="Get in touch" />
            </Actions>
          </IntroCard>

          <Box>
            <Player
              title="Commercial showreel"
              image={CommercialImage}
              src={CommercialAudio}
            />
          </Box>
        </IntroGridMobile>
      </ContainerDefault>
    </IntroContainer>
  );
}

export function IntroComponentTabletSmall() {
  return (
    <IntroContainer>
      <ContainerDefault>
        <IntroGrid className="flex py-16 gap-x-4">
          <Parallax y={[40, -40]} styleOuter={{ zIndex: 1, flex: 1 }}>
            <Box>
              <Player
                title="Commercial showreel"
                image={CommercialImage}
                src={CommercialAudio}
              />
            </Box>
          </Parallax>

          <Parallax y={[-40, 40]} styleOuter={{ zIndex: 2, flex: 2 }}>
            <IntroCard style={{ height: "375px" }} className="p-8">
              <h2>About me</h2>
              <p>
                I’m Shirlie Randall, and have been the voice for numerous brands
                and projects for over 20 years! I can adapt my voice to whatever
                style your project requires, from bright and upbeat to smooth
                and sultry or natural and warm to cool and fresh, plus many
                more.
              </p>

              <Signature />

              <Actions className="px-8 pb-10">
                <Action to="contact" value="Get in touch" />
                <ActionSecondary to="about" value="Read more" />
              </Actions>
            </IntroCard>
          </Parallax>
        </IntroGrid>
      </ContainerDefault>
    </IntroContainer>
  );
}

export function IntroComponentTablet() {
  return (
    <IntroContainer>
      <ContainerDefault>
        <IntroGrid className="flex py-16 gap-x-4">
          <Parallax y={[40, -40]} styleOuter={{ zIndex: 1, flex: 1 }}>
            <Box>
              <Player
                title="Commercial showreel"
                image={CommercialImage}
                src={CommercialAudio}
              />
            </Box>
          </Parallax>

          <Parallax y={[-40, 40]} styleOuter={{ zIndex: 2, flex: 2 }}>
            <IntroCard style={{ height: "375px" }} className="p-8">
              <h2>About me</h2>
              <p>
                I’m Shirlie Randall, and have been the voice for numerous brands
                and projects for over 20 years! I can adapt my voice to whatever
                style your project requires, from bright and upbeat to smooth
                and sultry or natural and warm to cool and fresh, plus many
                more.
              </p>

              <Signature />

              <Actions className="px-8 pb-10">
                <Action to="contact" value="Get in touch" />
                <ActionSecondary to="about" value="Read more" />
              </Actions>
            </IntroCard>
          </Parallax>

          <Parallax y={[40, -40]} styleOuter={{ zIndex: 1, flex: 1 }}>
            <Box>
              <Player
                title="Imaging showreel"
                image={ImagingImage}
                src={ImagingAudio}
              />
            </Box>
          </Parallax>
        </IntroGrid>
      </ContainerDefault>
    </IntroContainer>
  );
}

export function IntroComponent() {
  return (
    <IntroContainer>
      <ContainerDefault>
        <IntroGrid className="flex py-16 gap-x-4">
          <Parallax y={[40, -40]} styleOuter={{ zIndex: 1, flex: 1 }}>
            <Box>
              <Player
                title="Commercial showreel"
                image={CommercialImage}
                src={CommercialAudio}
              />
            </Box>
          </Parallax>

          <Parallax y={[-40, 40]} styleOuter={{ zIndex: 2, flex: 2 }}>
            <IntroCard style={{ height: "375px" }} className="p-8">
              <h2>About me</h2>
              <p>
                I’m <strong className="font-semibold">Shirlie Randall</strong>,{" "}
                a female British professional voice-over artist with over 20
                years of experience bringing brands and projects to life.
              </p>
              <p>
                Versatility is my strength — I can adapt my voice to perfectly
                match your project’s needs, whether it’s{" "}
                <strong className="font-semibold">bright and upbeat</strong>, to{" "}
                <strong className="font-semibold">smooth and sultry</strong>.
              </p>
              <p className="pr-64">
                Renowned for my excellent communication skills and ability to
                take direction; praised as a voice-over artist who can “nail a
                script” the first take.
              </p>

              <Signature />

              <Actions className="px-8 pb-10">
                <Action to="contact" value="Get in touch" />
                <ActionSecondary to="about" value="Read more" />
              </Actions>
            </IntroCard>
          </Parallax>

          <Parallax y={[40, -40]} styleOuter={{ zIndex: 1, flex: 1 }}>
            <Box>
              <Player
                title="Imaging showreel"
                image={ImagingImage}
                src={ImagingAudio}
              />
            </Box>
          </Parallax>
        </IntroGrid>
      </ContainerDefault>
    </IntroContainer>
  );
}

export default function Intro() {
  const [resi, setResi] = useState(3);

  function handleResize() {
    const w = document.documentElement.clientWidth;

    const size =
      w > 1290
        ? "DESKTOP"
        : w > 827
        ? "TABLET"
        : w > 767
        ? "TABLET SMALL"
        : "MOBILE";

    setResi(size);
  }

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (resi === "DESKTOP") return <IntroComponent />;

  if (resi === "TABLET") return <IntroComponentTablet />;

  if (resi === "TABLET SMALL") return <IntroComponentTabletSmall />;

  return <IntroComponentMobile />;
}

const IntroContainer = styled.div`
  background: white;
`;

const IntroGrid = styled.div`
  position: relative;
  padding-bottom: 200px;
`;

const IntroGridMobile = styled.div`
  position: relative;
  padding-bottom: 36px;
  margin-top: -70px;
`;

const IntroCard = styled.div`
  background: white;
  margin-top: -20px;
  flex: 2;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 2rem;
    font-family: ${font.sans.family};
    font-weight: ${font.sans.weight};
    padding-bottom: 0.2em;
  }

  p {
    padding-bottom: 1em;
    z-index: 2;
    position: relative;
  }
`;

const Box = styled.div`
  background-position: cover;
  height: 360px;
`;

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;
